import React, { FunctionComponent, ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';

interface IProductCardProps {
  title: string;
  body: string;
  linkTo: string;
  linkText: string;
  image: ReactElement;
}

export const ProductCard: FunctionComponent<IProductCardProps> = (props) => {
  return (
    <Card>
      <UnstyledLink to={props.linkTo}>
        <CardActionArea>
          {props.image}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.body}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            {props.linkText}
          </Button>
        </CardActions>
      </UnstyledLink>
    </Card>
  );
};
