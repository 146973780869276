import { Box, Grid, Typography } from '@material-ui/core';

import PageLayout from '../components/org.page-layout/page-layout.component';
import { ProductCard } from '../components/mol.product-card/product-card.component';
import React from 'react';
import { Spacing } from '../components/obj.constants/constants';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
import { convertToSlug } from '../utils/convertToSlug';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';
import { LineSpacer } from '../components/atm.line-spacer/line-spacer.styled';

const ProductsPage = (props: PageProps) => {
  const data = props.data as any;
  const pageMetadata = (data.markdownRemark as any).frontmatter;
  const edges = data.allFile.edges;
  const products = edges.map((edge: any) => ({
    title: edge.node.childMarkdownRemark.frontmatter.title,
    body: edge.node.childMarkdownRemark.frontmatter.productDescription,
    linkTo: convertToSlug(edge.node.childMarkdownRemark.frontmatter.title),
    linkText: 'Ver mais',
    image: (
      <GatsbyImage
        image={
          edge.node.childMarkdownRemark.frontmatter.productCardImage
            .childImageSharp.desktopImage
        }
        alt="Logo image"
        objectFit="contain"
      />
    ),
  }));
  return (
    <>
      <Seo title={pageMetadata.productsPageTitle} />

      <Box
        paddingTop={Spacing.XLarge}
        paddingBottom={Spacing.XLarge}
        paddingLeft={Spacing.Large}
        paddingRight={Spacing.Large}
      >
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={1} lg={2} />
          <Grid item xs={10} lg={8}>
            <Typography variant="h1">
              {pageMetadata.productsPageTitle}
            </Typography>
          </Grid>
          <Grid item xs={1} lg={2} />
          <LineSpacer height={Spacing.XLarge} />
          {products.map((product: any) => (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <ProductCard
                key={product.linkTo}
                title={product.title}
                body={product.body}
                linkTo={product.linkTo}
                linkText={product.linkText}
                image={product.image}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export const query = graphql`
  query ProductQuery {
    allFile(filter: { relativeDirectory: { eq: "products" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              productDescription
              productCardImage {
                childImageSharp {
                  desktopImage: gatsbyImageData(aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { fileName: { eq: "productsPage" } }) {
      frontmatter {
        productsPageTitle
      }
    }
  }
`;

export default ProductsPage;
